( function() {

	/*class ScrollSpy {
	 constructor(options = {}) {
	 this.options = {
	 sectionSelector: options.sectionSelector || '.section',
	 headerSelector: options.headerSelector || '.header',
	 activeClass: options.activeClass || 'active',
	 };
	 this.sections = document.querySelectorAll(this.options.sectionSelector);
	 this.header = document.querySelector(this.options.headerSelector);
	 this.activeSection = null;

	 this.observer = new IntersectionObserver(this.onIntersection.bind(this), {
	 root: null,
	 // rootMargin: `-${headerHeight + this.options.offset}px 0px 0px 0px`,
	 threshold: 0.5,
	 });

	 this.sections.forEach(section => this.observer.observe(section));
	 }

	 onIntersection(entries) {
	 entries.forEach(entry => {
	 if (entry.isIntersecting) {
	 this.setActive(entry.target);
	 }
	 });
	 }

	 setActive(section) {
	 if (this.activeSection) {
	 this.activeSection.classList.remove(this.options.activeClass);
	 }

	 this.activeSection = section;
	 section.classList.add(this.options.activeClass);
	 }
	 }

	 new ScrollSpy();*/


	class ScrollManager {
		constructor( options = {} ) {
			this.options = {
				scrollUpButtonId: 'scrollUp',
				scrollDownButtonId: 'scrollDown',
				sectionClassName: 'section',
				headerId: 'header',
				intersectionThreshold: 0.5,
				isActive: 'active',
			};

			Object.assign( this.options, options );

			this.buttonScrollUp = document.getElementById( this.options.scrollUpButtonId );
			this.buttonScrollDown = document.getElementById( this.options.scrollDownButtonId );
			this.sectionElements = document.getElementsByClassName( this.options.sectionClassName );
			this.headerElement = document.getElementById( this.options.headerId );

			this.sectionObserver = new IntersectionObserver( this.onIntersection.bind( this ), { threshold: this.options.intersectionThreshold } );

			this.init();
		}

		init() {
			this.buttonScrollUp.addEventListener( 'click', this.scrollUp.bind( this ) );
			this.buttonScrollDown.addEventListener( 'click', this.scrollDown.bind( this ) );

			window.addEventListener( 'resize', this.onResize.bind( this ) );

			for( let i = 0; i < this.sectionElements.length; i++ ) {
				this.sectionObserver.observe( this.sectionElements[ i ] );
			}

			this.makeSectionActive( this.sectionElements[ 0 ] );
		}

		onIntersection( entries ) {
			entries.forEach( ( entry ) => {
				if( entry.isIntersecting ) {
					this.makeSectionActive( entry.target );
					const isFirstSection = entry.target === this.sectionElements[ 0 ];
					const isLastSection = entry.target === this.sectionElements[ this.sectionElements.length - 1 ];
					this.buttonScrollUp.disabled = isFirstSection;
					this.buttonScrollDown.disabled = isLastSection;
				}
			} );
		}

		scrollToSection( section ) {
			if( section ) {
				const sectionTop = section.offsetTop - this.headerElement.offsetHeight;
				this.smoothScrollTo( sectionTop );
				this.makeSectionActive( section );
			}
		}

		smoothScrollTo( targetPosition ) {
			const startPosition = window.pageYOffset;
			const distance = targetPosition - startPosition;
			const duration = 300;
			let startTime = null;

			function animation( currentTime ) {
				if( startTime === null ) startTime = currentTime;
				const timeElapsed = currentTime - startTime;
				const run = easeInOutQuad( timeElapsed, startPosition, distance, duration );
				window.scrollTo( 0, run );
				if( timeElapsed < duration ) requestAnimationFrame( animation );
			}

			function easeInOutQuad( t, b, c, d ) {
				t /= d / 2;
				if( t < 1 ) return c / 2 * t * t + b;
				t--;
				return -c / 2 * ( t * ( t - 2 ) - 1 ) + b;
			}

			requestAnimationFrame( animation );
		}

		scrollUp() {
			const currentSection = document.querySelector( '.active' );
			const previousSection = currentSection.previousElementSibling;
			this.scrollToSection( previousSection );
		}

		scrollDown() {
			const currentSection = document.querySelector( '.active' );
			const nextSection = currentSection.nextElementSibling;
			this.scrollToSection( nextSection );
		}

		makeSectionActive( section ) {
			for( let i = 0; i < this.sectionElements.length; i++ ) {
				this.sectionElements[ i ].classList.remove( 'active' );
			}
			section.classList.add( 'active' );
		}

		onResize() {
			this.headerHeight = this.headerElement.offsetHeight;
			const currentSection = document.querySelector( '.active' );
			this.scrollToSection( currentSection );
		}
	}

	new ScrollManager();

	/*// Получаем ссылки на кнопки и секции
	 const buttonScrollUp = document.getElementById( 'scrollUp' );
	 const buttonScrollDown = document.getElementById( 'scrollDown' );
	 const sectionElements = document.getElementsByClassName( 'section' );
	 const headerElement = document.getElementById( 'header' );

	 const keyActions = {
	 'ArrowUp': scrollUp,
	 'ArrowDown': scrollDown,
	 };

	 let isKeyPressed = false;
	 let keyTimer;
	 let scrollTimeout;

	 // Создаем Intersection Observer
	 const sectionObserver = new IntersectionObserver( function( entries ) {
	 entries.forEach( function( entry ) {
	 // Если секция находится в зоне видимости, делаем ее активной
	 if( entry.isIntersecting ) {
	 makeSectionActive( entry.target );
	 // Проверяем, является ли секция первой или последней
	 const isFirstSection = entry.target === sectionElements[ 0 ];
	 const isLastSection = entry.target === sectionElements[ sectionElements.length - 1 ];
	 // Отключаем/включаем кнопки в зависимости от положения секции
	 buttonScrollUp.disabled = isFirstSection;
	 buttonScrollDown.disabled = isLastSection;
	 }
	 } );
	 }, { threshold: 0.5 } ); // Порог пересечения 50%

	 // Функция для прокрутки к указанной секции
	 function scrollToSection( section ) {
	 // Проверяем, является ли переданная секция действительной
	 if( section ) {
	 const sectionTop = section.offsetTop - headerElement.offsetHeight;
	 window.scrollTo( {
	 top: sectionTop,
	 behavior: 'smooth'
	 } );
	 // Делаем переданную секцию активной
	 makeSectionActive( section );
	 }
	 }

	 // Функция для прокрутки вверх
	 function scrollUp() {
	 // Получаем текущую активную секцию
	 const currentSection = document.querySelector( '.active' );
	 // Получаем предыдущую секцию
	 const previousSection = currentSection.previousElementSibling;
	 // Прокручиваем к предыдущей секции
	 scrollToSection( previousSection );
	 }

	 // Функция для прокрутки вниз
	 function scrollDown() {
	 // Получаем текущую активную секцию
	 const currentSection = document.querySelector( '.active' );
	 // Получаем следующую секцию
	 const nextSection = currentSection.nextElementSibling;
	 // Прокручиваем к следующей секции
	 scrollToSection( nextSection );
	 }

	 // Функция для обновления активной секции
	 function makeSectionActive( section ) {
	 // Удаляем класс 'active' у всех секций
	 for( let i = 0; i < sectionElements.length; i++ ) {
	 sectionElements[ i ].classList.remove( 'active' );
	 }
	 // Добавляем класс 'active' к переданной секции
	 section.classList.add( 'active' );
	 }

	 // Назначаем обработчики событий на кнопки
	 buttonScrollUp.addEventListener( 'click', scrollUp );
	 buttonScrollDown.addEventListener( 'click', scrollDown );

	 // Обработчик события нажатия клавиши клавиатуры
	 document.addEventListener( 'keydown', function( event ) {
	 const key = event.key;

	 if( key in keyActions && !isKeyPressed && !event.repeat ) {
	 isKeyPressed = true;
	 keyActions[ key ]();
	 event.preventDefault();
	 }
	 // // Если нажата клавиша "ArrowUp" (стрелка вверх), прокручиваем вверх
	 // if (key === 'ArrowUp') {
	 //     if (!isKeyPressed && !event.repeat) {
	 //         isKeyPressed = true; // Устанавливаем флаг, что клавиша нажата и удерживается
	 //         scrollUp(); // Запускаем прокрутку
	 //         event.preventDefault(); // Убираем стандартное поведение только при простом нажатии
	 //     }
	 // }
	 // // Если нажата клавиша "ArrowDown" (стрелка вниз), прокручиваем вниз
	 // else if (key === 'ArrowDown') {
	 //     if (!isKeyPressed && !event.repeat) {
	 //         isKeyPressed = true; // Устанавливаем флаг, что клавиша нажата и удерживается
	 //         scrollDown(); // Запускаем прокрутку
	 //         event.preventDefault(); // Убираем стандартное поведение только при простом нажатии
	 //     }
	 // }
	 } );

	 // Обработчик события отпускания клавиши клавиатуры
	 document.addEventListener( 'keyup', function( event ) {
	 const key = event.key;

	 if( key in keyActions ) {
	 isKeyPressed = false;
	 event.preventDefault();
	 }
	 // // Если отпущена клавиша "ArrowUp" (стрелка вверх) или "ArrowDown" (стрелка вниз)
	 // if (key === 'ArrowUp' || key === 'ArrowDown') {
	 //     isKeyPressed = false; // Сбрасываем флаг, что клавиша нажата и удерживается
	 //     event.preventDefault(); // Оставляем стандартное поведение только при отпускании клавиши
	 // }
	 } );

	 // Перебираем все секции и добавляем их в Intersection Observer
	 for( let i = 0; i < sectionElements.length; i++ ) {
	 sectionObserver.observe( sectionElements[ i ] );
	 }

	 // Обработчик события изменения размеров окна
	 window.addEventListener( 'resize', function() {
	 // При изменении размеров окна обновляем высоту заголовка и позицию прокрутки
	 headerHeight = headerElement.offsetHeight;
	 const currentSection = document.querySelector( '.active' );
	 scrollToSection( currentSection );
	 } );

	 // По умолчанию делаем первую секцию активной
	 makeSectionActive( sectionElements[ 0 ] );*/
} )();

( function() {

	/*// Функция для получения высоты элемента
	 function getElementHeight( element ) {
	 const style = window.getComputedStyle( element );
	 const height = element.offsetHeight;
	 const borderTopWidth = parseFloat( style.borderTopWidth );
	 const borderBottomWidth = parseFloat( style.borderBottomWidth );
	 const paddingTop = parseFloat( style.paddingTop );
	 const paddingBottom = parseFloat( style.paddingBottom );
	 return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
	 }

	 // Функция для обновления высоты всех элементов .section
	 function updateSectionHeights() {
	 const sections = document.querySelectorAll( '.section' );
	 sections.forEach( section => {
	 const height = getElementHeight( section );
	 console.log( `Высота элемента ${ section.className }: ${ height }px` );
	 } );
	 }

	 // Обновление высот при загрузке страницы
	 window.addEventListener( 'load', updateSectionHeights );

	 // Обновление высот при ресайзе окна
	 window.addEventListener( 'resize', updateSectionHeights );*/

	/* const colorThief = new ColorThief();
	 const images = document.querySelectorAll('.img-placeholder');
	 images.forEach(image => {
	 if (image.complete) {
	 image.parentNode.style.backgroundColor = `rgb(${colorThief.getColor(image)})`;
	 } else {
	 image.addEventListener('load', function () {
	 image.parentNode.style.backgroundColor = `rgb(${colorThief.getColor(image)})`;
	 });
	 }
	 }); */

	/* AOS */
	// AOS.init();

	/*new Pageable("#main", {
	 childSelector: "[data-anchor]", // CSS3 selector string for the pages
	 anchors: [], // define the page anchors
	 pips: true, // display the pips
	 animation: 300, // the duration in ms of the scroll animation
	 delay: 0, // the delay in ms before the scroll animation starts
	 throttle: 50, // the interval in ms that the resize callback is fired
	 orientation: "vertical", // or horizontal
	 swipeThreshold: 50, // swipe / mouse drag distance (px) before firing the page change event
	 freeScroll: false, // allow manual scrolling when dragging instead of automatically moving to next page
	 navPrevEl: false, // define an element to use to scroll to the previous page (CSS3 selector string or Element reference)
	 navNextEl: false, // define an element to use to scroll to the next page (CSS3 selector string or Element reference)
	 infinite: false, // enable infinite scrolling (from 0.4.0)
	 slideshow: { // enable slideshow that cycles through your pages automatically (from 0.4.0)
	 interval: 3000, // time in ms between page change,
	 delay: 0 // delay in ms after the interval has ended and before changing page
	 },
	 events: {
	 wheel: true, // enable / disable mousewheel scrolling
	 mouse: true, // enable / disable mouse drag scrolling
	 touch: true, // enable / disable touch / swipe scrolling
	 keydown: true, // enable / disable keyboard navigation
	 },
	 easing: function(currentTime, startPos, endPos, interval) {
	 // the easing function used for the scroll animation
	 return -endPos * (currentTime /= interval) * (currentTime - 2) + startPos;
	 },
	 onInit: function() {
	 // do something when the instance is ready
	 },
	 onUpdate: function() {
	 // do something when the instance updates
	 },
	 onBeforeStart: function() {
	 // do something before scrolling begins
	 },
	 onStart: function() {
	 // do something when scrolling begins
	 },
	 onScroll: function() {
	 // do something during scroll
	 },
	 onFinish: function() {
	 // do something when scrolling ends
	 },
	 });*/

	/* Актуальный год */
	const footerCopyright = document.querySelector( ".footer-copyright-year" );
	footerCopyright.innerHTML = `${ new Date().getFullYear() }`;


} )();